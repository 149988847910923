import $ from 'jquery';
import DatePicker from 'tui-date-picker';
import moment from 'moment';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'repeatContainer',
    'repeatWeekContainer',
    'repeatMonthContainer',
    'startDate',
    'endDate',
    'startTime',
    'endTime',
    'endRepeatDate',
    'repeatBookingInput',
    'repeatBookingLabel',
    'repeatInterval',
    'repeatOnDayInput',
    'monthOptions',
    'firstOccurrenceStartTime',
    'firstOccurrenceEndTime',
  ];

  static values = {
    allowPastBookings: Boolean,
    impersonating: Boolean,
    operatorBookingsEditableLimit: Number
  };

  initialize() {
    this.startDatePicker = this.initDatePicker(this.startDateTarget);
    this.endDatePicker = this.initDatePicker(this.endDateTarget);
    if (this.hasEndRepeatDateTarget) {
      this.endRepeatDatePicker = this.initDatePicker(this.endRepeatDateTarget);
    }

    this.addTargetEventListeners();

    const startDate = moment(this.startDatePicker.getDate());
    this.setMonthSelectOptions(startDate);
    this.setRepeatOnDay(startDate);
    this.startDatePicker.on('draw', () => {
      this.preventTouchEventsSubmittingParentForm();
    });

    this.endDatePicker.on('draw', () => {
      this.preventTouchEventsSubmittingParentForm();
    });
  }

  preventTouchEventsSubmittingParentForm = () => {
    const tuiDatePickers = document.querySelectorAll('.tui-datepicker');
    tuiDatePickers.forEach((tdp) => {
      tdp.addEventListener('touchend',  (event)  => {
        event.preventDefault(); // Prevent the form from submitting
      });
    });
  };

  connect() {
    this.element[this.identifier] = this;
  }

  initDatePicker(target) {
    const isImpersonating = this.impersonatingValue;
    const allowPastBookings = this.allowPastBookingsValue;
    const date28Days = moment().subtract(this.operatorBookingsEditableLimitValue, 'seconds');
    const rangeStart = allowPastBookings ? (isImpersonating ? null : date28Days) : moment();
    const rangeEnd = moment().add(18, 'months');

    const targetDate = target.value ? Date.parse(target.value) : null;
    const validDate = targetDate >= rangeStart && targetDate <= rangeEnd ? targetDate : new Date();
    const datePicker = new DatePicker($(target).data('container'), {
      date: validDate,
      input: {
        element: target,
        format: 'D, dd MMM YYYY',
      },
      calendar: {
        showToday: false,
      },
      weekStartDay: 'Mon',
      selectableRanges: [[rangeStart, rangeEnd]],
    });

    datePicker.on('change', () => {
      const event = new Event('change', { bubbles: true });
      target.dispatchEvent(event);
    });

    return datePicker;
  }

  addTargetEventListeners() {
    $(this.startDateTarget).change(this.startDateChanged.bind(this));
    $(this.endDateTarget).change(this.endDateChanged.bind(this));

    $(this.startTimeTarget).change(this.startTimeChanged.bind(this));
    $(this.endTimeTarget).change(this.endTimeChanged.bind(this));

    if (this.hasRepeatContainerTarget) {
      $(this.repeatContainerTarget).find('.end-option').click(this.updateEndConfigState);
    }

    if (this.hasRepeatBookingInputTarget) {
      $(this.repeatBookingInputTarget).change((e) => {
        if (e.target.checked) {
          $(this.repeatContainerTarget).removeClass('d-none');
          $(this.repeatBookingLabelTarget).text('I want a one-off booking');
          $(this.repeatIntervalTarget).trigger('change');
        } else {
          $(this.repeatContainerTarget).addClass('d-none');
          $(this.repeatBookingLabelTarget).text('I want a repeat booking');
        }
      });
    }

    if (this.hasRepeatIntervalTarget) {
      $(this.repeatIntervalTarget).change((e) => {
        switch (e.target.value) {
          case 'day':
            this.hideRepeatWeekContainer();
            this.hideRepeatMonthContainer();
            break;
          case 'week':
            this.hideRepeatMonthContainer();
            this.showRepeatWeekContainer();
            break;
          case 'month':
            this.hideRepeatWeekContainer();
            this.showRepeatMonthContainer();
            break;
        }
      });
    }
  }

  onMobileModalOpen() {
    const startDate = moment();
    const endDate = moment().add(1, 'hour');
    $(this.endTimeTarget)
      .val(this.setTimeToNextHalfOrHour(endDate).format(moment.HTML5_FMT.TIME))
      .trigger('change');
    $(this.startTimeTarget)
      .val(this.setTimeToNextHalfOrHour(startDate).format(moment.HTML5_FMT.TIME))
      .trigger('change');
    this.startDatePicker.setDate(startDate.toDate());
    this.endDatePicker.setDate(endDate.toDate());
   
  }

  setTimeToNextHalfOrHour(momentObj) {
    const minutes = momentObj.minutes();

    if (minutes < 30) {
      momentObj.minutes(30).seconds(0).milliseconds(0);
    } else {
      momentObj.add(1, 'hour').minutes(0).seconds(0).milliseconds(0);
    }

    return momentObj;
  }

  onCalendarModalOpen(e) {
    const { startDate, endDate } = e.detail;

    this.startDatePicker.setDate(startDate);
    this.endDatePicker.setDate(endDate);
    if (this.hasEndRepeatDateTarget) {
      const endRepeatDate = moment(endDate).add(1, 'week');
      this.endRepeatDatePicker.setDate(endRepeatDate.toDate());
    }

    $(this.endTimeTarget)
      .val(moment(endDate).format(moment.HTML5_FMT.TIME))
      .trigger('change');

    $(this.startTimeTarget)
      .val(moment(startDate).format(moment.HTML5_FMT.TIME))
      .trigger('change');
  }

  showRepeatMonthContainer() {
    const startDate = moment(this.startDatePicker.getDate());
    this.setMonthSelectOptions(startDate);

    $(this.repeatMonthContainerTarget).removeClass('d-none');
  }

  hideRepeatMonthContainer() {
    $(this.repeatMonthContainerTarget).addClass('d-none');
  }

  showRepeatWeekContainer() {
    $(this.repeatWeekContainerTarget).removeClass('d-none');
  }

  hideRepeatWeekContainer() {
    $(this.repeatWeekContainerTarget).addClass('d-none');
  }

  startDateChanged() {
    const date = moment(this.startDatePicker.getDate());
    this.setMonthSelectOptions(date);
    if (this.endDatePicker.getDate() < date) {
      this.endDatePicker.setDate(this.startDatePicker.getDate());
    }
    this.updateFirstOccurrenceStartTime();
    this.setRepeatOnDay(date);
  }

  endDateChanged() {
    const date = moment(this.endDatePicker.getDate());
    if (this.startDatePicker.getDate() > date) {
      this.startDatePicker.setDate(this.endDatePicker.getDate());
    }
    this.updateFirstOccurrenceEndTime();
  }

  startTimeChanged() {
    this.updateFirstOccurrenceStartTime();
  }

  endTimeChanged() {
    this.updateFirstOccurrenceEndTime();
  }

  updateFirstOccurrenceStartTime() {
    const date = moment(this.startDatePicker.getDate());
    const time = moment(this.startTimeTarget.value, moment.HTML5_FMT.TIME);
    const dateTime = date.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
    });
    this.setFirstOccurrenceStartTimeValue(
      dateTime.format(moment.HTML5_FMT.DATETIME_LOCAL)
    );
  }

  updateFirstOccurrenceEndTime() {
    const date = moment(this.endDatePicker.getDate());
    const time = moment(this.endTimeTarget.value, moment.HTML5_FMT.TIME);
    const dateTime = date.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
    });
    this.setFirstOccurrenceEndTimeValue(
      dateTime.format(moment.HTML5_FMT.DATETIME_LOCAL)
    );
  }

  setRepeatOnDay(date) {
    if (!this.anyRepeatDaysSelected()) {
      $(this.repeatOnDayInputTargets)
        .prop('checked', false)
        .filter(`[value="${date.get('day')}"]`)
        .prop('checked', true);
    }
  }

  setMonthSelectOptions(date) {
    if (this.hasRepeatIntervalTarget) {
      $(this.repeatIntervalTarget)
        .find('option[value="month"]')
        .prop('disabled', !date.isValid());

      if (!date.isValid()) {
        $(this.repeatIntervalTarget)
          .find('option:enabled:first')
          .prop('selected', true)
          .trigger('change');
      }
    }
    if (!this.hasMonthOptionsTarget || !date.isValid()) {
      return;
    }
    const $monthOptionsSelect = $(this.monthOptionsTarget);
    $monthOptionsSelect
      .find('[value=same_date]')
      .text(`Monthly on the ${date.format('Do')}`);
    $monthOptionsSelect
      .find('[value=same_day]')
      .text(
        `Monthly on the ${this.occurrenceWithinMonth(date)} ${date.format(
          'dddd'
        )}`
      );
  }

  occurrenceWithinMonth(date) {
    const stringified = [
      'zeroth',
      'first',
      'second',
      'third',
      'fourth',
      'fifth',
      'sixth',
    ];
    const allDays = Array.from({ length: date.date() }, (x, i) =>
      date.startOf('month').add(i, 'days').format('d')
    );
    if (date.daysInMonth() - allDays.length < 7) return 'last';
    return stringified[allDays.filter((d) => d === date.format('d')).length];
  }

  updateEndConfigState(e) {
    const $target = $(e.target);
    const $endConfig = $target.closest('.end-config');
    const $thisOption = $target.closest('.end-option');
    $endConfig
      .find('.end-option')
      .not($thisOption)
      .find('input:not(input:radio)')
      .prop('disabled', true); // disable the other inputs
    $thisOption.find('input:not(input:radio)').removeAttr('disabled').focus(); // enable selected input and focus

    if ($target.is(':not(input:radio)')) {
      // clicking anywhere in the parent div triggers the radio
      $thisOption.find('input:radio').trigger('click');
    }
  }

  setFirstOccurrenceStartTimeValue(val) {
    if (this.hasFirstOccurrenceStartTimeTarget) {
      $(this.firstOccurrenceStartTimeTarget).val(val);
    }
  }

  setFirstOccurrenceEndTimeValue(val) {
    if (this.hasFirstOccurrenceEndTimeTarget) {
      $(this.firstOccurrenceEndTimeTarget).val(val);
    }
  }
  
  anyRepeatDaysSelected() {
    let anyChecked = false;
    for (const t of this.repeatOnDayInputTargets) {
      anyChecked = $(t).prop('checked');
      if(anyChecked) break;
    }
    return anyChecked;
  }
}
