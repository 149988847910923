import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="range-label"
export default class extends Controller {
  static targets = ['rangeValue', 'rangeSlider'];

  connect() {
    this.setRangeValue(this.rangeSliderTarget.value);
    this.rangeSliderTarget.addEventListener('input', (event) => {
      this.setRangeValue(event.target.value);
    });
  }

  setRangeValue(value) {
    this.rangeValueTarget.textContent = value;
  }
}
